import React, { useEffect } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { get } from "lodash";

import { ContentBlock } from "../../components/Blocks";
import Contents from "../../components/Contents";
import Pages from "../../components/Pages";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import { makeUrl, removePreloader } from "../../helpers";
import { Share } from "../../widgets";
import Page404 from "../404";

export const query = graphql`
	query PostQuery($slug: String!) {
		hasura {
			posts(where: { slug: { _eq: $slug } }) {
				slug
				title
				content_blocks
				seo
				type
			}
		}
	}
`;

export default function Abstinence({ data, location }) {
	const hasuraData = data.hasura;
	const post = get(hasuraData, "posts[0]", {});

	const title = get(post, "title", "");
	const content_blocks = get(post, "content_blocks", []);
	const url = makeUrl.post(post);

	useEffect(() => {
		removePreloader();
	}, []);

	return Object.keys(post).length
		? <Pages entity={post} url={url}>
			<ContentBlock key={"Breadcrumbs"}>
				<div className="container">
					<Breadcrumbs currentLabel={title} />
				</div>
			</ContentBlock>
			<ContentBlock
				key={"media-content"}
				mount={content_blocks && !!content_blocks.length}
			>
				<div className={"container"}>
					<h1 className={"h1"} itemProp="headline">{title}</h1>
					<Contents items={content_blocks} />
				</div>
			</ContentBlock>
			<Share url={url} pageTitleFull={title} />
		</Pages>
		: <Page404 location={location} />;
}

Abstinence.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object,
};

Abstinence.defaultProps = {
	data: {},
	location: {},
};
